import React, { memo } from 'react';
import './ToggleSound.css';
import song from "./bgmusic.mp3";

const ToggleSound = ({ sound, toggleSound }) => {
  const audio = new Audio(song);
  audio.loop = true;
  audio.play();
  
  return (
  <button
    className={`ToggleSound ${sound && 'active'}`}
    onClick={() => {
      audio.loop = true;
      audio.play();
    }}
    title={sound ? 'Disable Sound' : 'Enable Sound'}
  >
    <i className={`fa fa-volume-${sound ? 'up' : 'mute'}`} />
  </button>
  )

};

export default memo(ToggleSound);
